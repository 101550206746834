import { ILanguage } from "../types";

export enum LanguagesActionTypes{
    FETCH_LANGUAGES = "FETCH_LANGUAGES",
    FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS",
    FETCH_LANGUAGES_ERROR = "FETCH_LANGUAGES_ERROR"
};
export interface GetAllLanguages {
    data: Array<ILanguage>;
}
export interface FetchLanguagesAction {
    type: LanguagesActionTypes.FETCH_LANGUAGES;
}
export interface FetchSuccessLanguagesAction {
    type: LanguagesActionTypes.FETCH_LANGUAGES_SUCCESS;
    payload: Array<ILanguage>;
  }
  
  export interface FetchErrorLanguagesAction {
    type: LanguagesActionTypes.FETCH_LANGUAGES_ERROR;
    payload: string;
  }
  
  export type LanguagesActions =
    | FetchLanguagesAction
    | FetchSuccessLanguagesAction
    | FetchErrorLanguagesAction;