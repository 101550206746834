export interface IPremiumFeature{
    id: string;
    name: string,
    numberOfSubscriptions: number,
    numberOfBankAccounts: number,
    yourMonthlySoftwareCosts: number,
    teamWorks: number
}

//REDUX
export enum PremiumFeatureActionTypes{
    GET_PREMIUM_FEATURES = "GET_PREMIUM_FEATURES",
    GET_PREMIUM_FEATURES_SUCCESS = "GET_PREMIUM_FEATURES_SUCCESS",
    GET_PREMIUM_FEATURES_ERROR = "GET_PREMIUM_FEATURES_ERROR",
    EDIT_PREMIUM_FEATURE = "EDIT_PREMIUM_FEATURE",
}
export interface PremiumFeatureState{
    isLoading: boolean;
    premiumFeatures: IPremiumFeature[];
}
export interface GetPremiumFeaturesAction{
    type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES;
}
export interface GetPremiumFeaturesSuccessAction{
    type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_SUCCESS;
    payload: IPremiumFeature[];
}
export interface GetPremiumFeaturesErrorAction{
    type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_ERROR;
    payload: string;
}
export interface EditPremiumFeaturesAction{
    type: PremiumFeatureActionTypes.EDIT_PREMIUM_FEATURE;
    payload: IPremiumFeature;
}

export type PremiumFeatureAction =
| GetPremiumFeaturesAction
| GetPremiumFeaturesSuccessAction
| GetPremiumFeaturesErrorAction
| EditPremiumFeaturesAction
;