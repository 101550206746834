import * as yup from "yup"

export const RegisterSchema = yup.object({
  email: yup
    .string()
    .email("Please, enter correct Email!")
    .required("The field is required!"),
  firstName: yup.string().required("The Field is required!"),
  // photo: yup.string().required("Поле не повинне бути пустим"),
  phoneNumber: yup
    .string()
    .matches(
      /^((\+?3)?8)?((0\(\d{2}\)?)|(\(0\d{2}\))|(0\d{2}))\d{7}$/,
      "Incorrect number format"
    )
    // .required("Поле не повинне бути пустим"),
    ,
  password: yup
    .string()
    .min(5, "Пароль повинен містити мінімум 5 символів")
    .matches(/[0-9a-zA-Z]/, "Пароль може містить латинські символи і цифри")
    .required("Поле не повинне бути пустим"),
  confirmPassword: yup
    .string()
    .min(5, "Пароль повинен містити мінімум 5 символів")
    .oneOf([yup.ref("password"), null], () => "Паролі повинні співпадати")
    .required("Поле не повинне бути пустим"),
});