import { useEffect } from "react";
import { useActions } from "../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import UserVisitBarChart from "./UserVisitBarChart";
import { useParams, useSearchParams } from "react-router-dom";
import EclipseWidget from "../../../../../common/eclipse";
import { Pagination, Typography } from "antd";

export const UserVisitPage: React.FC = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { GetUserVisits } = useActions();
  const { isLoading, visits } = useTypedSelector((store) => store.userVisit);

  const pageSize = 12;
  useEffect(() => {
    const pageString = searchParams.get("page");
    fetchData(
      pageString && !isNaN(Number(pageString)) ? Number(pageString) : 1
    );
  }, [searchParams, setSearchParams, id]);

  const fetchData = async (pageIndex: number) => {
    if (id) {
      await GetUserVisits({
        pageIndex: pageIndex,
        pageSize: pageSize,
        userId: id,
      });
    }
  };
  const handlePagination = async (page: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("page", page.toString());
    setSearchParams(updatedSearchParams);
  };
  return (
    <div className='pt-5'>
      {isLoading ? (
        <EclipseWidget />
      ) : visits.items && visits.items.length > 0 ?  (
        <UserVisitBarChart data={visits.items} />
      ) : (<Typography.Title className='text-center'>NOT FOUND VISITS</Typography.Title>)}
      <Pagination
        defaultCurrent={1}
        total={visits.totalCount}
        current={visits.pageIndex}
        onChange={handlePagination}
        pageSize={pageSize}
        showSizeChanger={false}
        className="mt-3 text-center"
      />
    </div>
  );
};
