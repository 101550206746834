import {ILanguagesState} from './types';
import { LanguagesActions, LanguagesActionTypes } from './types/GetAllLanguages';
const initialState : ILanguagesState = {
    languages: [],
    loading: false,
    error: null
};
export const languageReducer = (
    state = initialState,
    action: 
      | LanguagesActions
  ) => {
    switch (action.type) {
      case LanguagesActionTypes.FETCH_LANGUAGES:
        return { ...state, loading: true };
  
      case LanguagesActionTypes.FETCH_LANGUAGES_SUCCESS:
        return { ...state, loading: false, languages: action.payload };
  
      case LanguagesActionTypes.FETCH_LANGUAGES_ERROR:
        return { ...state, loading: false, error: action.payload };
  
      // case GetCategoryActionTypes.GET_CATEGORY:
      //   return { ...state, loading: true };
  
      // case GetCategoryActionTypes.GET_CATEGORY_SUCCESS:
      //   return { ...state, loading: false, categoryData: action.payload };
  
      // case GetCategoryActionTypes.GET_CATEGORY_ERROR:
      //   return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };