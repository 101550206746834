import React, {useEffect} from 'react';
import Swal from 'sweetalert2';
import http_common from '../../../api/configurations/http_common';
//import {confirmEmailAsync} from "../../services/authentication";
export async function confirmEmailAsync(token : string) {

    //return authenticationService
       // .confirmEmail({token})
       http_common.post('api/Account/confirm-email', {token})
        .then(
            () => {
                Swal.fire({
                    icon: "success",
                    title: "Nice!",
                    text: "Succefully confirmation!",
                  });
                //successMessage(authenticationMessages.SUCCESSFUL_EMAIL_CONFIRMATION);
            },
            () => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Confirmation failed",
                  });
            })
        .catch(() => {
            //Swal.fire
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Confirmation failed",
              });
        });
}
function ConfirmEmailPage() {
    useEffect(() => {
        async function fetchData() {
            let data = window.location.pathname.split('/');
            let tokenParts = data.slice(2, -1);
            let token = tokenParts.join('/');

            await confirmEmailAsync(token);
        }

        fetchData();
    }, []);

    return (
        <div className="d-flex authEmail justify-content-center align-items-center text-center">
            <div className="center">
                <h1 className="title">Subscription Manager</h1>

                <h2>Email confirmation</h2>
            </div>
        </div>
    );
}

export default ConfirmEmailPage;