import { Dispatch } from "redux";
import http from "../../../api/configurations/http_common";
import { AuthAction, AuthActionTypes, ILoginErrors, ILoginResponse, IUser, LogoutAuthAction } from "./types";
//import { decodeToken, isExpired, useJwt } from "react-jwt";
import axios, { AxiosError } from "axios";
import setAuthToken from '../../../helpers/setAuthToken';
import { ILogin } from './types';
import { message } from 'antd';
import jwtDecode from "jwt-decode";
import { setFlagsFromString } from "v8";


export const LoginUser = (data: ILogin) => async (dispatch: Dispatch<AuthAction>) => {
  try {
    // console.log(process.env.REACT_APP_SERVER_URL);
    const response = await http.post<ILoginResponse>("api/Account/login", data);
    const { token, refreshToken } = await response.data;
    setAuthUserByTokens(token, refreshToken, dispatch);
    return Promise.resolve();

  } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<ILoginErrors>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
       return Promise.reject();
  }
}

    export const setAuthUserByTokens = (accessToken: string | undefined, refreshToken : string | undefined, dispatch: Dispatch<any>) => {
      if(!accessToken || !refreshToken){
        return;
      }
      
      setAuthToken(accessToken);
      localStorage.accessToken = accessToken;
      localStorage.refreshToken = refreshToken;
      const dataUser : any = jwtDecode(accessToken);
      
      //console.log('data:' , dataUser);
      //const isMyTokenExpired = isExpired(token);
      //const dataUser = jwt.decode(token, { json: true });
      
      const user: IUser = {
        email: dataUser!.Email,
        name: dataUser!.Name,
        // image: dataUser!.image,roles
        roles: dataUser!['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      };
      //console.log('user: ',user);
      dispatch({
        type: AuthActionTypes.LOGIN_AUTH_SUCCESS,
        payload: user,
      });
    
    
    }

export const LogoutUser = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      
      const refreshToken : string | null = localStorage.getItem('refreshToken');
      await http.post("api/Account/logout", {refreshToken});
      setAuthToken('');
     
      dispatch({ type: AuthActionTypes.LOGOUT_AUTH });

      //return Promise.resolve();

    } 
    catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<ILoginErrors>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          //return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
    finally{
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return Promise.resolve();
    }

  }
}
export const LogOutSpecial = () => {
  return async (dispatch: Dispatch<AuthAction>)  => {
    try {
      //alert('223');
      dispatch({
        type: AuthActionTypes.LOGOUT_AUTH,
      });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return Promise.resolve();
    } 
    catch (err: any) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data.message);
      }

      return Promise.reject();
    }
}
};
export const UpdateTokens = () => async (dispatch: Dispatch<AuthAction>) => {
  try {
    const data  = {
      refreshToken : localStorage.getItem('refreshToken'),
      token: localStorage.getItem('accessToken')
    }; 
    
    const response = await http.post<ILoginResponse>("api/Account/refresh-token", data);
    //const { token } = await response.data;
    
    setAuthUserByTokens(response.data.token, response.data.refreshToken, dispatch);
    return Promise.resolve();

  } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<ILoginErrors>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
       return Promise.reject();
  }
}
