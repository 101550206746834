import { IPagination } from "../../../../../../common/request/IPagination";
import { IPaginationResponse } from "../../../../../../common/response/IPaginationResponse";

export interface IGetUserVisitsResponse extends IPaginationResponse{
    items: VisitDataItem[];
}

export interface VisitDataItem {
    monthName: string;
    year: number;
    counter: number;
}

export interface IGetUserVisitsRequest extends IPagination{
    userId: string;
}

//REDUX
export interface UserVisitState{
    isLoading: boolean;
    visits: IGetUserVisitsResponse;
}

export enum UserVisitActionTypes{
    GET_USER_VISITS = 'GET_USER_VISITS',
    GET_USER_VISITS_SUCCESS = 'GET_USER_VISITS_SUCCESS',
    GET_USER_VISITS_ERROR = 'GET_USER_VISITS_ERROR'
}
export interface IGetUserVisits{
    type: UserVisitActionTypes.GET_USER_VISITS;
}
export interface IGetUserVisitsSuccess{
    type: UserVisitActionTypes.GET_USER_VISITS_SUCCESS;
    payload: IGetUserVisitsResponse;
}
export interface IGetUserVisitsError{
    type: UserVisitActionTypes.GET_USER_VISITS_ERROR;
    payload: any;
}
export type UserVisitAction =
IGetUserVisits
| IGetUserVisitsSuccess
| IGetUserVisitsError;