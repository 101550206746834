import moment from "moment";
import { ISubscription } from "./types";
import { Checkbox, Table } from "antd";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import EclipseWidget from "../../../../../common/eclipse";
import { useEffect } from "react";
import { useActions } from "../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import './styles.css';

export const UserSubscriptionsPage: React.FC = () => {
  const columns = [
    {
      title: "Date / time / price (YYYY/DD/MM)",
      dataIndex: "dateAndPrice",
      key: "date",
      render: (_: any, record: ISubscription) => (
        <div className="d-flex flex-column">
          {record.transactions &&
            record.transactions.map((t) => (
              <div className={`d-flex justify-content-between ${record.billingCycleGenerationTransactionsIds?.includes(t.id) ? 'billing-cycle-generation' : ''}`}>
                <p>{moment(t.createdDate).format("YYYY/DD/MM HH:mm:ss")}</p>
                <p>{`${t.sum} ${t.currency.lettersSign}`}</p>
              </div>
            ))}
        </div>
      ),
    },
    {
      title: "Display name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: ISubscription) => (
        <div className="d-flex flex-column">
          <p>{record.displayName && `(${record.displayName})`}</p>
          <p>{record.name && `(${record.name})`}</p>
        </div>
      ),
    },
    {
      title: "Source",
      dataIndex: "souce",
      key: "source",
      render: (_: any, record: ISubscription) => (
        <div>
          <p>
            {record.bank && record.bank.name}{" "}
            {record.card && `(${record.card?.cardNumber})`}
            {record.userCustomPaymentMethod &&
              `${record.userCustomPaymentMethod.name} `}
            {record.userCustomPaymentMethod &&
              record.userCustomPaymentMethod.card &&
              `(${record.userCustomPaymentMethod.card})`}
          </p>
        </div>
      ),
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      key: "billingCycle",
      render: (_: any, record: ISubscription) => (
        <div className="d-flex flex-column">
          <p>{record.billingCycle.name}</p>
        </div>
      ),
    },
    {
      title: "IsCustom",
      dataIndex: "isCustom",
      key: "isCustom",
      render: (_: any, record: ISubscription) => (
        <div className="d-flex justify-content-center">
          <Checkbox disabled checked={record.isCustom}></Checkbox>
        </div>
      ),
    },
    {
        title: "Service or custom service",
        dataIndex: "service",
        key: "service",
        render: (_: any, record: ISubscription) => (
          <div className="d-flex justify-content-center">
            {record.service ? <p>Service: {record.service.name}</p> : ''}
            {record.customService ? <p>Custom Service: {record.customService.name}</p> : ''}
          </div>
        ),
      },
  ];
  const { subscriptions, isLoading } = useTypedSelector(
    (state) => state.subscription
  );
  const { id } = useParams();
  const {GetSubscriptions} = useActions();
  const fetchData = async () => {
    if(id){
        await GetSubscriptions({userId: id});
    }
  }
  useEffect(() => {
        fetchData();
  }, []);
  return (
    <>
      {isLoading && <EclipseWidget />}
      {subscriptions && (
        <Table
          columns={columns}
          dataSource={subscriptions}
          pagination={false}
        />
      )}
    </>
  );
};
