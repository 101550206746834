import {combineReducers} from "redux";
import { userReducer } from "../../components/admin/Users/UsersList/reducer";
import { transactionReducer } from "../../components/admin/Users/UsersList/UserPage/UserTransactionsPage/reducer";
import { authReducer } from "../../components/auth/login/reducer";
import { languageReducer } from "../../components/Languages/reducer";
import { profileReducer } from "../../components/UserData/reducer";
import { premiumFeatureReducer } from "../../components/admin/PremiumFeatures/reducer";
import { userVisitReducer } from "../../components/admin/Users/UsersList/UserPage/UserVisitPage/reducer";
import { adminSubscriptionReducer } from "../../components/admin/Users/UsersList/UserPage/UserSubscriptionsPage/reducer";
export const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    language: languageReducer,
    user: userReducer,
    transaction : transactionReducer,
    premiumFeature: premiumFeatureReducer,
    userVisit: userVisitReducer,
    subscription: adminSubscriptionReducer
});
export type RootState = ReturnType<typeof rootReducer>;