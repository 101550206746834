export interface ILogin{
    email: string,
    password: string
}
export enum AuthActionTypes {
    LOGIN_AUTH = "LOGIN_AUTH",
    LOGIN_AUTH_SUCCESS = "LOGIN_AUTH_SUCCESS",
    LOGIN_AUTH_ERROR = "LOGIN_AUTH_ERROR",
    LOGOUT_AUTH = "LOGOUT_AUTH",
    UPDATE_TOKENS = "UPDATE_TOKENS"
  }
  export interface IUser {
    email: string,
    name: string,
    // image: string,
    roles: string
}
  export interface AuthState {
    user: IUser;
    isAuth: boolean;
  }
  export interface LoginAuthAction {
    type: AuthActionTypes.LOGIN_AUTH
}

export interface LoginAuthSuccessAction {
    type: AuthActionTypes.LOGIN_AUTH_SUCCESS;
    payload: IUser;
}

export interface LoginAuthErrorAction {
    type: AuthActionTypes.LOGIN_AUTH_ERROR,
    payload: string
}
export interface LogoutAuthAction {
    type: AuthActionTypes.LOGOUT_AUTH;
}
export interface UpdateTokensAction{
  type: AuthActionTypes.UPDATE_TOKENS;
}
export interface ILogout{
  refreshToken : string
}

export type AuthAction =
  | LoginAuthAction
  | LoginAuthSuccessAction
  | LoginAuthErrorAction
  | LogoutAuthAction
  | UpdateTokensAction;
  // export interface ILogin {
  //   email: string;
  //   password: string;
  //   invalid:string
  // }
  
  export interface ILoginError {
    password: Array<string>;
    email: Array<string>;
    invalid: Array<string>;
  }
  export interface ILoginResponse {
    token: string,
    refreshToken : string
  }
  export interface ILoginErrors {
    errors: ILoginError,
    status: number
  }
export default ILogin;