import { UserVisitAction, UserVisitActionTypes, UserVisitState } from "./types";

  
  const initialState: UserVisitState = {
    visits: {
      items: [],
      pageIndex: 1,
      pageSize: 12,
      hasNextPage: false,
      hasPreviousPage: false,
      totalCount: 0,
      totalPages: 0,
    },
    isLoading: false,
  };
  
  export const userVisitReducer = (
    state = initialState,
    action: UserVisitAction
  ): UserVisitState => {
    switch (action.type) {
      case UserVisitActionTypes.GET_USER_VISITS:
        return {
          ...state,
          isLoading: true,
        };
      case UserVisitActionTypes.GET_USER_VISITS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          visits: action.payload,
        };
      case UserVisitActionTypes.GET_USER_VISITS_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  