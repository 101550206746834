import { Button, DatePicker, Form, Input, Select } from "antd"
import { useEffect, useState } from "react";
import http from "../../../../../../api/configurations/http_common";
import { AddTransactionRequest, Card, CreateTransactionProps, Currency } from "./types";

export const CreateTransaction : React.FC<CreateTransactionProps> = ({ onSubmit, userId }) => {
    const { Option } = Select;
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [cards, setCards] = useState<Card[]>([]);
    useEffect(() => {
        const fetchCurrencies = async () => {
          try {
            const response = await http.get<Currency[]>('/api/Currency/get-all-currencies');
            setCurrencies(response.data);
          } catch (error) {
            console.error(error);
          }
        };
        const fetchCards = async () => {
          try {
            const response = await http.get<Card[]>('/api/AdminCard/get-user-cards', { params: {userId: userId}});
            setCards(response.data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchCurrencies();
        fetchCards();
      }, []);
      const [form] = Form.useForm();
      const handleFinish = (values: AddTransactionRequest) => {
        onSubmit(values);
        form.resetFields();
      };
    return (
        <Form form={form} onFinish={handleFinish} className="d-flex flex-wrap gap-2">
        <Form.Item
          name="createdDate"
          label="Created Date"
          rules={[{ required: true, message: 'Please select a date' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sum"
          label="Sum"
          rules={[{ required: true, message: 'Please enter a sum' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="cardId"
          label="Card ID"
          rules={[{ required: true, message: 'Please enter a card ID' }]}
        >
          <Select placeholder="Select card to add transaction">
            {cards.map((card) => (
              <Option key={card.id} value={card.id} >
                {card.bankName} {card.cardNumber}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="currencyId"
          label="Currency ID"
          rules={[{ required: true, message: 'Please select a currency' }]}
        >
          <Select placeholder="Select currency to add trasnaction">
            {currencies.map((currency) => (
              <Option key={currency.id} value={currency.id}>
                {currency.name} ({currency.lettersSign})
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    )
}