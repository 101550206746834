import { Dispatch } from "react";
import http from "../../api/configurations/http_common";
import { ILanguage } from "./types";
import { LanguagesActions, LanguagesActionTypes } from "./types/GetAllLanguages";

export const fetchLanguages = () => {
    return async (dispatch: Dispatch<LanguagesActions>) => {
      try {
        dispatch({
          type: LanguagesActionTypes.FETCH_LANGUAGES,
        });
        const response = await http.get<ILanguage[]>('api/Language/get-all');
  
        dispatch({
          type: LanguagesActionTypes.FETCH_LANGUAGES_SUCCESS,
          payload: response.data,
        });
      } catch (error: any) {
        dispatch({
          type: LanguagesActionTypes.FETCH_LANGUAGES_ERROR,
          payload: error,
        });
      }
    };
  };