import axios, { AxiosError } from "axios";
import { useActions } from '../../hooks/useActions';
//import { LogOutSpecial, LogoutUser } from "../../components/auth/login/actions";
import { store } from "../../store/index";
import { refresh } from "../refresh";
import { LogOutSpecial } from "../../components/auth/login/actions";

type RequestQueueItem = {
  config: any;
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
};
let isRefreshing = false;
let failedQueue: RequestQueueItem[] = [];
  
const http = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        "Content-type" : "application/json"
    }
});
const processQueue = (error: AxiosError | null, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(http(prom.config));
      }
    });
  
    failedQueue = [];
  
    if (token) {
        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  };
  
  http.interceptors.request.use(
    (configuration: any) => {
      const accessToken = localStorage.getItem("accessToken");
  
      if (accessToken) {
        if (configuration.headers) {
          configuration.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
  
      return configuration;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        (error.response.status === 400 || error.response.status === 404 || error.response.status === 500) &&
        error.config.url === process.env.REACT_APP_SERVER_URL + "api/Account/refresh-token"
      ) {
        //const {LogOutSpecial} = useActions();
        //LogOutSpecial();
        store.dispatch(LogOutSpecial());
      }
  
      if (error.response.status === 401 && !error.config._retry) {
        if (!isRefreshing) {
          
          isRefreshing = true;
          const accessToken = localStorage.getItem("accessToken");
          const refreshToken = localStorage.getItem("refreshToken");
  
          try {
            //alert('22');
            //const {LogOutSpecial} = useActions();
            //LogOutSpecial();
            //alert("Log out");
            const result = await refresh(accessToken, refreshToken);
            const newAccessToken = result.token;
            const newRefreshToken = result.refreshToken;
  
            localStorage.setItem("accessToken", newAccessToken);
            localStorage.setItem("refreshToken", newRefreshToken);
  
            processQueue(null, newAccessToken);
          } catch (internalError) {
            processQueue(internalError as any, null);
          } finally {
            isRefreshing = false;
          }
        }
  
        const retryRequest = new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject, config: error.config });
        });
  
        return retryRequest;
      }
  
      return Promise.reject(error);
    }
   );
// http.interceptors.response.use(response => {
//     return response;
// }, async (error) => {
//     if (error.response.status === 401) {
//         try {
//             const { UpdateTokens } = useActions();
//             await UpdateTokens();
//             return http(error.config);
//           }
//           catch (internalError) {
//             return Promise.reject(internalError);
//           }
//         }
    
//         // if (
//         //   (error.response.status === statusCode.NOT_FOUND ||
//         //     error.response.status === statusCode.INTERNAL_SERVER_ERROR) &&
//         //   error.config.url == AUTHENTICATION_URLS.REFRESH_TOKEN
//         // ) {
//         //   store.dispatch(logout());
//         // }
//         return Promise.reject(error);
//        }
// );

export default http;