import { Menu, MenuProps } from "antd";
import { AiFillDollarCircle, AiOutlineInfoCircle, AiOutlineTransaction } from "react-icons/ai";
import { ImEnter } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";

export const UserPageSideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];


  // const isMenuItemActive = (key: string) => {
  //   const path = location.pathname;
  //   return path.includes(key);
  // };


  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
    isActive?: boolean
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      isActive
    } as MenuItem;
  }
  const items: MenuProps["items"] = [
    getItem("Information", "information", <AiOutlineInfoCircle/>),
    getItem("Transactions", "transactions", <AiOutlineTransaction />),
    getItem("Visits", "visits", <ImEnter />),
    getItem("Subscriptions", "subscriptions", <AiFillDollarCircle />)
  ];


  const onClick: MenuProps["onClick"] = (e) => {
    switch(e.key){
        case 'information':
            navigate('');
            break;
        case 'transactions':
            navigate('transactions');
            break;
        case 'visits':
          navigate('visits');
          break;
        case 'subscriptions':
          navigate('subscriptions');
          break;
    }
  };
  return (
    <div>
      <Menu
        onClick={onClick}
        defaultSelectedKeys={[lastPathSegment]}
        defaultOpenKeys={["sub1"]}
        mode="horizontal"
        items={items}
      />
    </div>
  );
};
