import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, Outlet, Link } from "react-router-dom";
import "./App.css";
import LoginPage from "./components/auth/login";
import HomePage from "./components/home";
import HomeLayout from "./containers/homeLayout";
import RegisterPage from "./components/auth/register/register";
import ConfirmEmailPage from "./components/auth/emailConfirmation";
import RecoverPassword from "./components/auth/recoverPassword";
import ResetPassword from "./components/auth/recoverPassword/resetPassword";
import SendEmail from "./components/auth/recoverPassword/recoverSuccess";
import ProfilePage from "./components/UserData/Profile";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { UserPageLayout } from "./components/admin/Users/UsersList/UserPage/UserPageLayout";
import { UserTransactionsPage } from "./components/admin/Users/UsersList/UserPage/UserTransactionsPage/UserTransactionsPage";
import NotFound from "./containers/NotFound/NotFound";
import { ResetPasswordSuccess } from "./components/auth/recoverPassword/ResetPasswordSuccess";
import FallbackComponent from "./components/common/FallbackComponent";
import { UserVisitPage } from "./components/admin/Users/UsersList/UserPage/UserVisitPage/UserVisitPage";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { UserSubscriptionsPage } from "./components/admin/Users/UsersList/UserPage/UserSubscriptionsPage/UserSubscriptionsPage";
// import { UserPage } from './components/admin/Users/UsersList/UserPage/UserPage';

const AdminPanelLayout = lazy(() => import("./containers/adminPanelLayout"));
const UsersList = lazy(
  () =>
    import(
      "./components/admin/Users/UsersList/UsersList/UsersList"
    )
);
const Versions = lazy(() => import("./components/admin/Versions/Versions"));
const UserPage = lazy(
  () => import("./components/admin/Users/UsersList/UserPage/UserPage")
);
const PushNotifications = lazy(
  () => import("./components/admin/PushNotifications/PushNotifications")
);
const PremiumFeatures = lazy(
  () =>
    import(
      "./components/admin/PremiumFeatures/PremiumFeaturesList/PremiumFeaturesList"
    )
);
function App() {
  const {
    isAuth,
    user: { roles },
  } = useTypedSelector((store) => store.auth);
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<HomePage />}></Route>
        <Route
          path="login"
          element={
            <ProtectedRoute isSignedIn={isAuth}>
              <LoginPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="register"
          element={
            <ProtectedRoute isSignedIn={isAuth}>
              <RegisterPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="confirm-email/:token*"
          element={<ConfirmEmailPage />}
        ></Route>
        <Route path="recover-password" element={<RecoverPassword />}></Route>
        <Route path="/recoverPassword/sendEmail" element={<SendEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reset-password/success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="/profile" element={<ProfilePage />} />
        {isAuth && roles && roles.includes("Administrator") && (
          <Route
            path="/adminPanel"
            element={
              <Suspense fallback={<FallbackComponent />}>
                <AdminPanelLayout />
              </Suspense>
            }
          >
            <Route
              path=""
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <UsersList />
                </Suspense>
              }
            />
            <Route
              path="/adminPanel/pushNotifications"
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <PushNotifications />
                </Suspense>
              }
            ></Route>

            <Route
              path="/adminPanel/users"
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <UsersList />
                </Suspense>
              }
            ></Route>
            <Route
              path="/adminPanel/versions"
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <Versions />
                </Suspense>
              }
            ></Route>
            <Route
              path="/adminPanel/premiumFeatures"
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <PremiumFeatures />
                </Suspense>
              }
            ></Route>
            <Route
              path="/adminPanel/users/:id"
              element={
                <Suspense fallback={<FallbackComponent />}>
                  <UserPageLayout />
                </Suspense>
              }
            >
              {/* //path='/adminPanel/users/:id/info' */}
              <Route index element={<UserPage />} />
              <Route
                element={<UserTransactionsPage />}
                path="/adminPanel/users/:id/transactions"
              />
              <Route
                element={<UserSubscriptionsPage />}
                path="/adminPanel/users/:id/subscriptions"
              />
              <Route
                element={<UserVisitPage />}
                path="/adminPanel/users/:id/visits"
              />
            </Route>
          </Route>
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
