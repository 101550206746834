import { useFormik } from "formik";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import EclipseWidget from "../../common/eclipse";
import { IProfile } from "./types";

//import './index.css'

const ProfilePage = () => {
  const { GetProfileData, fetchLanguages } = useActions();
 // const { fetchLanguages } = useActions();
  //const { profile : {email, phoneNumber, name}, loading } = useTypedSelector( store => store.profile);
  const { profile, loading } = useTypedSelector((store) => store.profile);
  const { languages } = useTypedSelector((store) => store.language);
  useEffect(() => {
    
    fetchLanguages();
    GetProfileData();
    //console.log(profile );
    //console.log(+ "profile");
  }, [GetProfileData, fetchLanguages]);
  let initialValues: IProfile = profile;
  const onHandleSubmit = async (values: IProfile) => {
    try {
      //await updateCategory(values);
      //navigator('//categories');
    } catch (error) {
      const serverErrors = error; //as UpdateErrors;

      console.log(serverErrors);
    }
  };
  //initialValues
  const formik = useFormik({
    initialValues: profile,
    // validationSchema,
    onSubmit: onHandleSubmit,
    validateOnBlur: true,
  });
  //console.log("test");
  const { errors, touched, handleChange, handleSubmit, setFieldValue, values } =
    formik;
  return (
    <section>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="container py-5 ">
        <h2 className="text-center pb-5">My profile:</h2>
        {loading && <EclipseWidget />}
        {!loading && (
          <>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
                Email:
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="staticEmail"
                  value={values.email}
                />
              </div>
            </div>
            <hr />
            <label htmlFor="basic-url">Name:</label>
            <div className="row">
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>
            </div>
            <label htmlFor="basic-url">Phone:</label>
            <div className="row">
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.phoneNumber}
                />
              </div>
            </div>
            <p className="p-0 mb-2">Language:</p>
            <Form.Select value={values.langId!}>
              {
                languages.map(l => (
                  <option key={l.id}>
                    {l.name}
                  </option>
                ))
              }
            </Form.Select>
          </>
        )}
      </div>
    </section>
  );
};

export default ProfilePage;
