import axios, { AxiosError } from "axios";
import http from "../../../../../../api/configurations/http_common";
import { IGetUserVisitsRequest, IGetUserVisitsResponse, UserVisitAction, UserVisitActionTypes } from "./types";
import { Dispatch } from "react";

export const GetUserVisits = (data: IGetUserVisitsRequest) => async (dispatch: Dispatch<UserVisitAction>) => {
    dispatch({
      type: UserVisitActionTypes.GET_USER_VISITS
    });
    try {
      const response = await http.get<IGetUserVisitsResponse>("api/UserVisit/get-user-visits", {
        params:
        {
          PageIndex: data.pageIndex,
          PageSize: data.pageSize,
          userId: data.userId
        }
      });
      dispatch({
        type: UserVisitActionTypes.GET_USER_VISITS_SUCCESS,
        payload: await response.data
      });
      return Promise.resolve();
  
    } catch (err: any) {
      dispatch({
        type: UserVisitActionTypes.GET_USER_VISITS_ERROR,
        payload: err
      });
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
}