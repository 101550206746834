import http from "./configurations/http_common";


export const refresh = async (token: any, refreshToken: any ) => {
    if (!refreshToken) {
        return null;
    }

    const response = await http.post(
        process.env.REACT_APP_SERVER_URL + 'api/Account/refresh-token',
        {refreshToken: refreshToken, token: token}
    );

    return response.data;
};