import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    reducer: rootReducer,
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});
//export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));