import { BackTop, Button, Checkbox, Divider, Pagination, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useActions } from "../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import http from "../../../../../../api/configurations/http_common";
import EclipseWidget from "../../../../../common/eclipse";
import { CreateTransaction } from "./CreateTransaction";
import { SyncPlaid } from "./SyncPlaid";
import { AddTransactionRequest, ITransaction } from "./types";

export const UserTransactionsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 100;
  const { GetTransactions, RemoveTransaction } = useActions();
  //const navigate = useNavigate();
  const { id } = useParams();
  const { transactions, isLoading } = useTypedSelector(
    (state) => state.transaction
  );
  const fetchData = async (pageIndex: number) => {
    if (id) {
      await GetTransactions({
        pageIndex: pageIndex,
        pageSize: pageSize,
        userId: id,
      });
    } else {
    }
  };
  const columns = [
    {
      title: "Id / time",
      dataIndex: "info",
      key: "id",
      render: (_: any, record: ITransaction) => (
        <div className="d-flex flex-column">
          <p>{record.id}</p>
          <p>Bank Id: {record.transactionFromBankId}</p>
          <p>{moment(record.createdInDatabaseDate).format("YYYY/DD/MM HH:mm:ss")}</p>
          <Button danger onClick={async () => await RemoveTransaction(record.id)}>Remove</Button>
        </div>
      ),
    },
    {
      title: "Transaction time (YYYY/DD/MM)",
      dataIndex: "info",
      key: "id",
      render: (_: any, record: ITransaction) => (
        <div className="d-flex flex-column">
          <p>{moment(record.createdDate).format("YYYY/DD/MM HH:mm:ss")}</p>
        </div>
      ),
    },
    {
        title: "Price",
        dataIndex: "sum",
        key: "sum",
        render: (_: any, record: ITransaction) => (
              <div>
                  <p>{record.sum}{record.currencyCode}</p>
              </div>
          ),
      },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (_: any, record: ITransaction) => (
        <div className="d-flex flex-column">
          <p>{record.cardName}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
        title: "Subscription Name",
        dataIndex: "subscriptionName",
        key: "subscriptionName",
        render: (_: any, record: ITransaction) => (
            <div className="d-flex flex-column">
              <p>{record.subscriptionName} {record.subscriptionDisplayName ? `(${record.subscriptionDisplayName})` : null}</p>
            </div>
          ),
      },
  ];
  const [isControlHidden, setIsControlHidden]= useState<boolean>(false);
  useEffect(() => {
    handle();
  }, [searchParams, setSearchParams, id]);
  const handle = async () => {
    const pageString = searchParams.get("page");
    fetchData(
      pageString && !isNaN(Number(pageString)) ? Number(pageString) : 1
    );
  }
  const handlePagination = async (page: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set('page', page.toString());
    setSearchParams(updatedSearchParams);
  }
  const handleCreate = (values: AddTransactionRequest) => {
      http.post('api/AdminTransaction/add-transaction', values)
      .then(response => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your transaction has been saved',
          showConfirmButton: false,
          timer: 1000
        });
        handle();
      })
      .catch(er => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Something went wrong with your transaction!',
          showConfirmButton: false,
          timer: 1000
        });
      });
  }
  return (
    <div className="pt-3">
      {isLoading && <EclipseWidget />}
      <BackTop />
      <Checkbox checked={!isControlHidden} onChange={(ev) => setIsControlHidden(!isControlHidden)}>
      Show controls
    </Checkbox>
      {!isControlHidden && <div className="mx-4 mt-2">
        <div>
        <Divider/>
          <SyncPlaid userId={id as string} handleAdd={handle}></SyncPlaid>
          <Divider/>
          <p className="fw-bold"> Create transaction: </p>
        </div>
        <CreateTransaction onSubmit={handleCreate} userId={id as string}></CreateTransaction>
      </div>}
      <div >
        <p className="fw-bold mt-2"> All transactions: {transactions.totalCount}</p>
      </div>
      <Table
        columns={columns}
        dataSource={transactions.items}
        pagination={false}
      />
      <Pagination
        defaultCurrent={1}
        total={transactions.totalCount}
        current={transactions.pageIndex}
        onChange={handlePagination}
        pageSize={pageSize}
        showSizeChanger={false}
        className="mt-3 text-center"
      />
    </div>
  );
};
