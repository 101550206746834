import { Dispatch } from "react";
import {
  IPremiumFeature,
  PremiumFeatureAction,
  PremiumFeatureActionTypes,
} from "./types";
import http from "../../../api/configurations/http_common";
import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";

export const GetPremiumFeatures =
  () => async (dispatch: Dispatch<PremiumFeatureAction>) => {
    dispatch({
      type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES,
    });
    try {
      const response = await http.get<IPremiumFeature[]>(
        "api/PremiumFeature/get-all"
      );
      dispatch({
        type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_SUCCESS,
        payload: await response.data,
      });
      return Promise.resolve();
    } catch (err: any) {
      dispatch({
        type: PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_ERROR,
        payload: err,
      });
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };
  export const EditPremiumFeature =
  (data: IPremiumFeature) => async (dispatch: Dispatch<PremiumFeatureAction>) => {
    try {
      const response = await http.put<IPremiumFeature>(
        "api/PremiumFeature/edit", data
      );
      dispatch({
        type: PremiumFeatureActionTypes.EDIT_PREMIUM_FEATURE,
        payload: await response.data,
      });
      return Promise.resolve();
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Something went wrong! ' + err.message,
        confirmButtonText: 'OK',
      });
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };
