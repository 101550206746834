import Title from 'antd/es/typography/Title';
import React from 'react';
import logo from '../../assets/images/logo512.png';
import './styles.css';
export default function HomePage() {
  return (
    <div className='logo-container d-flex justify-content-center align-items-center'>
      <img src={logo} alt="logo" className='logo'/>
    </div>
  )
}