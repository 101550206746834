import { IAdminSubscriptionState, SubscriptionAction, SubscriptionActionTypes } from "./types";

const initialState : IAdminSubscriptionState = {
    isLoading: false,
    subscriptions: []
}

export const adminSubscriptionReducer = (
    state = initialState,
    action: SubscriptionAction
) : IAdminSubscriptionState => {
    switch(action.type){
        case SubscriptionActionTypes.GET_SUBSCRIPTIONS:
            return{
                ...state,
                isLoading: true,
            }
        case SubscriptionActionTypes.GET_SUBSCRIPTIONS_SUCCESS:
            return{
                ...state,
                isLoading: false,
                subscriptions: action.payload
            }
        case SubscriptionActionTypes.GET_SUBSCRIPTIONS_ERROR:
            return{
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}