import { Button, Checkbox, DatePicker, Form } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from 'dayjs';
import http from "../../../../../../api/configurations/http_common";
import Swal from "sweetalert2";
import moment, { Moment } from "moment";
const { RangePicker } = DatePicker;

export interface SyncPlaidRequest{
    from: Date | null;
    to: Date | null;
    userId: string;
}
export interface SyncPlaidProps{
    handleAdd: () => void;
    userId: string;
}
export const SyncPlaid : React.FC<SyncPlaidProps> = ({userId, handleAdd}) => {
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    const [maxDate, setMaxDate] = useState<Dayjs>(dayjs());


    const handleCalculate = async () => {
      try{
      const response = await http.head("api/AdminSubscription/calculate-subscriptions-by-user-id", {
        params: 
        {
          userId: userId
        }});
        handleAdd();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Successfully calculation subscriptions',
          showConfirmButton: false,
          timer: 1000
        })
      }
      catch(err: any){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!' + err
        })
      }
    }
    const disabledDate = (current: Dayjs) => {

      return current.isAfter(maxDate.endOf('day'));
    };
    const handleFromDateChange = (value: Dayjs | null, dateString: string) => {
        setFromDate(value);
      };
      
      const handleToDateChange = (value: Dayjs | null, dateString: string) => {
        setToDate(value);
      };
      
      useEffect(() => {
        if (fromDate !== null && toDate !== null) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }, [fromDate, toDate]);
      
      const handleSubmit = () => {
        const requestData: SyncPlaidRequest = {
          from: fromDate?.toDate() || null,
          to: toDate?.toDate() || null,
          userId: userId,
        };
        
        const response = http.post("api/AdminTransaction/sync-transactions-by-user", requestData).then(
          x => {Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Succesfully synced',
            showConfirmButton: false,
            timer: 1000
          })
          handleAdd();
        }
        ).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! ' + err,
          })
        });
      };
      const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        setChecked(!checked);
        setIsDisabled(!checked);
       // if (checked) {
       //   setIsDisabled(true);
        //} 
        //else if (!fromDate || !toDate) {
         // setIsDisabled(true);
        //}
      };
    return (
        <>
        <p className="fw-bold"> Sync transactions: </p>
        {!isDisabled && (
          <div className="d-flex gap-2">
            <DatePicker value={fromDate} onChange={handleFromDateChange} disabled={isDisabled} disabledDate={disabledDate}/>
            <DatePicker value={toDate} onChange={handleToDateChange} disabled={isDisabled} disabledDate={disabledDate}/>
          </div>
        )}
        
        <div style={{ marginTop: 16 }}>
          <Checkbox checked={checked} onChange={handleCheckboxChange}>
            Sync by last syncronization
          </Checkbox>
        </div>
        <Button.Group className="mt-3 mb-3">
        <Button onClick={handleCalculate}>Calculate Subscriptions</Button>
          <Button  onClick={handleSubmit}>
            Sync Transactions
          </Button>
        </Button.Group>
      </>
    )
}