import { Dispatch } from "react";
import { IGetSubscriptionsRequest, IGetSubscriptionsResponse, SubscriptionAction, SubscriptionActionTypes } from "./types";
import axios, { AxiosError } from "axios";
import http from "../../../../../../api/configurations/http_common";

export const GetSubscriptions = (data: IGetSubscriptionsRequest) => async (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch({
      type: SubscriptionActionTypes.GET_SUBSCRIPTIONS
    });
    try {
      const response = await http.get<IGetSubscriptionsResponse>("api/AdminSubscription/get-subscriptions-by-user-id", {
        params:
        {
          userId: data.userId
        }
      });
      dispatch({
        type: SubscriptionActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
        payload: await response.data.subscriptions
      });
      return Promise.resolve();
  
    } catch (err: any) {
      dispatch({
        type: SubscriptionActionTypes.GET_SUBSCRIPTIONS_ERROR,
        payload: err
      });
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  }