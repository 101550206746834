import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { setAuthUserByTokens } from "./components/auth/login/actions";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
let accessToken = localStorage.accessToken;
let refreshToken = localStorage.refreshToken;
if(accessToken && refreshToken && accessToken !== 'undefined' && refreshToken !== 'undefined'){
  setAuthUserByTokens(accessToken, refreshToken, store.dispatch);
}
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
