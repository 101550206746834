import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useActions } from "../../../hooks/useActions";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import EclipseWidget from "../../common/eclipse";
import InputGroup from "../../common/InputGroup";
import { InitResetValues, ResetPasswordRequest } from "./types";
import { ResetPasswordSchema } from "./validate";
import "./styles.css";

const ResetPassword = () => {
  const { resetPassword } = useActions();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const initialValues: InitResetValues = {
    password: "",
    confirmPassword: "",
  };

  const onHandleSubmit = async () => {
    setLoading(true);
    setError(false);
    const requestData: ResetPasswordRequest = {
      userId: searchParams.get("userId"),
      token: searchParams.get("code"),
      ...values,
    };
    try {
      await resetPassword(requestData);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Password has been changed",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("success");
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: onHandleSubmit,
  });
  const { errors, touched, handleChange, handleSubmit, values } = formik;
  return (
    <>
      <Helmet>
        <title>Password recover</title>
      </Helmet>
      <div className="row w-100">
        {/* <div className="col-4"> */}
        <h1 className="m-2">Password Recovery</h1>
        {/* </div> */}
        <div className="flex-grow-1 d-flex flex-column justify-content-center">
          {error && (
            <div className="alert alert-dismissible alert-danger text-center">
              Something went wrong
            </div>
          )}
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="w-100">
                <label htmlFor='password' className="form-label"> New password:</label> 
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className={`form-control ${
                    touched.password && errors.password ? "is-invalid" : ""
                  }`}
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  className={`btn ${
                    passwordVisible ? "btn-success" : "btn-secondary"
                  }`}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  style={{ height: "100%" }}
                >
                  {passwordVisible ? <RiEyeCloseFill /> : <RiEyeFill />}
                </button>
              </div>

              <div className="form-group">
                <label htmlFor="confirmPassword">Retype new password:</label>
                <div className="input-group">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className={`form-control ${
                      touched.confirmPassword && errors.confirmPassword
                        ? "is-invalid"
                        : ""
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <button
                  type="button"
                  className={`btn ${
                    confirmPasswordVisible ? "btn-success" : "btn-secondary"
                  }`}
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  style={{ height: "100%" }}
                >
                  {confirmPasswordVisible ? <RiEyeCloseFill /> : <RiEyeFill />}
                </button>
                  {/* <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={() =>
                        setConfirmPasswordVisible(!confirmPasswordVisible)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {confirmPasswordVisible ? (
                        <RiEyeCloseFill className="text-success" />
                      ) : (
                        <RiEyeFill className="text-muted" />
                      )}
                    </span>
                  </div> */}
                </div>
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
              <div className="form-group mt-3">
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  disabled={loading}
                >
                  Reset Password
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
        <div className="col-4"></div>
      </div>

      {loading && <EclipseWidget />}
    </>
  );
};

export default ResetPassword;
