import axios, { AxiosError } from "axios";
import { Dispatch } from "react";
import Swal from "sweetalert2";
import http from "../../../../../../api/configurations/http_common";
import { IGetTransactionsAdminRequest, IGetTransactionsAdminResponse, TransactionAction, TransactionActionTypes } from "./types";

export const GetTransactions = (data: IGetTransactionsAdminRequest) => async (dispatch: Dispatch<TransactionAction>) => {
  dispatch({
    type: TransactionActionTypes.GET_TRANSACTIONS
  });
  try {
    const response = await http.get<IGetTransactionsAdminResponse>("api/AdminTransaction/get-user-transactions", {
      params:
      {
        PageIndex: data.pageIndex,
        PageSize: data.pageSize,
        userId: data.userId
      }
    });
    dispatch({
      type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS,
      payload: await response.data
    });
    return Promise.resolve();

  } catch (err: any) {
    dispatch({
      type: TransactionActionTypes.GET_TRANSACTIONS_ERROR,
      payload: err
    });
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<any>;
      if (serverError && serverError.response) {
        const { errors } = serverError.response.data;
        return Promise.reject(errors);
      }
    }
    return Promise.reject();
  }
}
export const RemoveTransaction = (id: string) => async (dispatch: Dispatch<TransactionAction>) => {
  try {
    const response = await http.delete("api/AdminTransaction/delete-transaction-by-id", {
      params:
      {
       transactionId: id
      }
    });
    dispatch({
      type: TransactionActionTypes.REMOVE_TRANSACTION,
      payload: id
    });
    return Promise.resolve();

  } catch (err: any) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: 'Sorry'
    })
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<any>;
      if (serverError && serverError.response) {
        const { errors } = serverError.response.data;
        return Promise.reject(errors);
      }
    }
    return Promise.reject();
  }
}