import * as AuthActionCreators from "../../components/auth/login/actions";
import * as RegisterActionCreators from "../../components/auth/register/actions";
import * as RecoverPasswordActionCreator from "../../components/auth/recoverPassword/action";
import * as ProfileActionCreators from "../../components/UserData/actions";
import * as LanguageActionCreators from "../../components/Languages/actions";
import * as UserActionCreators from "../../components/admin/Users/UsersList/actions";
import * as TransactionActionCreators from "../../components/admin/Users/UsersList/UserPage/UserTransactionsPage/actions";
import * as PremiumFeatureActionCreators from "../../components/admin/PremiumFeatures/actions";
import * as UserVisitActionCreators from "../../components/admin/Users/UsersList/UserPage/UserVisitPage/actions";
import * as SubscriptionActionCreators from "../../components/admin/Users/UsersList/UserPage/UserSubscriptionsPage/actions";
const actions = {
    ...AuthActionCreators,
    ...RegisterActionCreators,
    ...RecoverPasswordActionCreator,
    ...ProfileActionCreators,
    ...LanguageActionCreators,
    ...UserActionCreators,
    ...TransactionActionCreators,
    ...PremiumFeatureActionCreators,
    ...UserVisitActionCreators,
    ...SubscriptionActionCreators
};
export default actions;