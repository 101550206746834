import { IPagination } from "../../../../../../common/request/IPagination";
import { IPaginationResponse } from "../../../../../../common/response/IPaginationResponse";

export interface ITransaction{
    id: string;
    sum: number;
    currencyCode: string | null;
    description: string | null;
    createdDate: Date;
    subscriptionName: string | null;
    subscriptionDisplayName : string | null;
    cardName: string | null;
    createdInDatabaseDate: Date;
    transactionFromBankId: string;
}
export interface CreateTransactionProps {
    onSubmit: (values: AddTransactionRequest) => void;
    userId: string;
}
export interface AddTransactionRequest{
    createdDate: Date;
    description: string;
    sum: number;
    cardId: string;
    currencyId: number;
}

export interface IGetTransactionsAdminResponse extends IPaginationResponse{
    items: ITransaction[];
}
export interface IGetTransactionsAdminRequest extends IPagination{
    userId: string;
}
export interface Currency {
    id: number;
    name: string;
    lettersSign: string;
}
export interface Card{
    id: number;
    cardNumber: string;
    bankName: string;
}
  

// REDUX

export enum TransactionActionTypes{
    GET_TRANSACTIONS = "GET_TRANSACTIONS",
    GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS",
    GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR",
    REMOVE_TRANSACTION = "REMOVE_TRANSACTION"
    // ADD_TRANSACTION = "ADD_TRANSACTION"
}

export interface GetTransactionsAction{
    type: TransactionActionTypes.GET_TRANSACTIONS;
}
export interface GetTransactionsSuccessAction{
    type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS;
    payload: IGetTransactionsAdminResponse;
}
export interface GetTransactionsErrorAction{
    type: TransactionActionTypes.GET_TRANSACTIONS_ERROR;
    payload: string;
}
export interface RemoveTransactionAction{
    type: TransactionActionTypes.REMOVE_TRANSACTION;
    payload: string;
}
export interface TransactionState{
    transactions: IGetTransactionsAdminResponse;
    isLoading: boolean;
}

// export interface AddTransactionAction{
//     type: TransactionActionTypes.ADD_TRANSACTION;
//     payload: 
// }

export type TransactionAction = 
| GetTransactionsAction
| GetTransactionsSuccessAction
| GetTransactionsErrorAction
| RemoveTransactionAction
;