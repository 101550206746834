import {
  TransactionAction,
  TransactionActionTypes,
  TransactionState,
} from "./types";

const initialState: TransactionState = {
  transactions: {
    items: [],
    pageIndex: 1,
    pageSize: 20,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 0,
  },
  isLoading: false,
};

export const transactionReducer = (
  state = initialState,
  action: TransactionAction
): TransactionState => {
  switch (action.type) {
    case TransactionActionTypes.GET_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case TransactionActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: action.payload,
      };
    case TransactionActionTypes.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case TransactionActionTypes.REMOVE_TRANSACTION:
      const updatedTransactions = state.transactions.items.filter(
        (t) => t.id !== action.payload
      );
      return {
        ...state,
        transactions: {
          ...state.transactions,
          items: updatedTransactions,
          totalCount: state.transactions.totalCount - 1,
        },
      };
    default:
      return state;
  }
};
