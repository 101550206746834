import React from 'react'
import { Navigate } from 'react-router-dom'

export interface ProtectedRouteProps{
  isSignedIn: boolean;
  children: any;
}
export const ProtectedRoute : React.FC<ProtectedRouteProps> = ({ isSignedIn, children }) => {
  if (isSignedIn) {
    return <Navigate to="/" replace />
  }
  return children
}
export default ProtectedRoute;