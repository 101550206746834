import { IPagination } from "../../../../common/request/IPagination";
import { IPaginationResponse } from "../../../../common/response/IPaginationResponse";

export interface IUserRegisterStatsResponse extends IPaginationResponse{
    items: IUserRegisterStat[];
}

export interface IUserRegisterStat{
    month: number;
    year: number;
    monthName: string;
    counter: number;
}
export interface IUserAdmin{
    id: string;
    name: string;
    surname: string;
    registrationDay: Date;
    phone: string;
    businessTypeName?: string;
    subscriptionPlan?: string;
    transactionsQuantity: number;
    transactionSubscriptionsQuantity: number;
    customSubscriptionsQuantity: number;
    subscriptionsQuantity: number;
    inAppCounter: number;
}
export interface BusinessType{
    id:number;
    name:string;
    quantity?: number;
}
export interface SubscriptionPlan{
    id :number,
    name:string,
    quantity?: number;
}
export interface IDetailedUser{
    id: string;
}
//export interface IPaginated
export interface IGetUsersAdminResponse extends IPaginationResponse{
    items: IUserAdmin[];
    // user: IDetailedUser;
    
}
export interface IGetUsersRequest extends IPagination{
    search: string | null;
    businessTypes?: number[];
    subscriptionPlans?: string[];
}
// REDUX
export enum UserActionTypes{
    GET_USERS = "GET_USERS",
    GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
    GET_USERS_ERROR = "GET_USERS_ERROR",
    DELETE_USER = "DELETE_USER",
    GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS",
    GET_BUSINESS_TYPES = "GET_BUSINESS_TYPES",
}
export interface GetUsersAction{
    type: UserActionTypes.GET_USERS;
}
export interface GetBusinessTypes{
    type: UserActionTypes.GET_BUSINESS_TYPES;
    payload: BusinessType[];
}
export interface GetSubscriptionPlans{
    type: UserActionTypes.GET_SUBSCRIPTION_PLANS;
    payload: SubscriptionPlan[];
}
export interface DeleteUserAction{
    type: UserActionTypes.DELETE_USER;
    payload: string;
}
export interface GetUsersSuccessAction{
    type: UserActionTypes.GET_USERS_SUCCESS;
    payload: IGetUsersAdminResponse;
}
export interface GetUsersErrorAction{
    type: UserActionTypes.GET_USERS_ERROR;
    payload: string;
}



export interface UserState{
    users: IGetUsersAdminResponse;
    businessTypes: BusinessType[];
    subscriptionPlans: SubscriptionPlan[];
    isLoading: boolean;
}

export type UserAction = 
| GetUsersAction 
| GetUsersSuccessAction 
| GetUsersErrorAction
| DeleteUserAction
| GetBusinessTypes
| GetSubscriptionPlans
;