import { Link } from 'react-router-dom';


const SendEmail = () => {
	return (
		<>
		<h1 className='text-center mt-4'>Link to recover password was sended to email.</h1>
		<Link to="/">To Main</Link>
		</>
	)
}

export default SendEmail;