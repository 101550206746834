import { UserAction, UserActionTypes, UserState } from "./types";

const initialState: UserState = {
    users: {
        items: [],
        pageIndex: 1,
        pageSize: 20,
        hasNextPage: false,
        hasPreviousPage: false,
        totalCount: 0,
        totalPages: 0
    },
    businessTypes: [],
    subscriptionPlans: [],
    isLoading: false
};

export const userReducer = (state = initialState, action: UserAction) : UserState => {
    switch (action.type) {
      case UserActionTypes.GET_USERS:
        return {
          ...state,
          isLoading: true,
        };
      case UserActionTypes.GET_USERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          users: action.payload,
        };
      case UserActionTypes.GET_USERS_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case UserActionTypes.GET_BUSINESS_TYPES:
        return {
          ...state,
          businessTypes: action.payload,
        };
      case UserActionTypes.GET_SUBSCRIPTION_PLANS:
        return {
          ...state,
          subscriptionPlans: action.payload,
        };
      default:
        return state;
    }
}