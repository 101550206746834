import axios, { AxiosError } from "axios";
import { Dispatch } from "react";
import http from "../../../../api/configurations/http_common";
import {
  BusinessType,
  IGetUsersAdminResponse,
  IGetUsersRequest,
  SubscriptionPlan,
  UserAction,
  UserActionTypes,
} from "./types";
const paramsSerializer = (params: any) => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        searchParams.append(key, value);
      }
    } else {
      if(key && params[key]){

        searchParams.set(key, params[key]);
      }
    }
  }

  return searchParams.toString();
};
export const GetUsers =
  (data: IGetUsersRequest) => async (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.GET_USERS,
    });
    try {
      // console.log(process.env.REACT_APP_SERVER_URL);
      // console.log('sss');
      const response = await http.get<IGetUsersAdminResponse>(
        "api/User/get-users",
        {
          params: {
            PageIndex: data.pageIndex,
            PageSize: data.pageSize,
            Search: data.search,
            SubscriptionPlans: data.subscriptionPlans,
            BusinessTypesIds: data.businessTypes
            // Action: data?.action? JSON.stringify([...data!.action]) : null!,
          },
          paramsSerializer: paramsSerializer,
        }
      );

      //const { items } = await response.data;
      dispatch({
        type: UserActionTypes.GET_USERS_SUCCESS,
        payload: await response.data,
      });
      return Promise.resolve();
    } catch (err: any) {
      dispatch({
        type: UserActionTypes.GET_USERS_ERROR,
        payload: err,
      });
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };
export const DeleteUser =
  (userId: string) => async (dispatch: Dispatch<UserAction>) => {
    try {
      const response = await http.put<IGetUsersAdminResponse>(
        "api/User/delete-account-for-user",
        null,
        {
          params: {
            userId: userId,
          },
        }
      );
      dispatch({
        type: UserActionTypes.DELETE_USER,
        payload: userId,
      });
      return Promise.resolve();
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };
export const GetBusinessTypes =
  () => async (dispatch: Dispatch<UserAction>) => {
    try {
      const response = await http.get<BusinessType[]>(
        "api/AdminBusinessType/get-all-with-quantity"
      );
      dispatch({
        type: UserActionTypes.GET_BUSINESS_TYPES,
        payload: await response.data,
      });
      return Promise.resolve();
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };
  export const GetSubscriptionPlans =
  () => async (dispatch: Dispatch<UserAction>) => {
    try {
      const response = await http.get<SubscriptionPlan[]>(
        "api/AdminSubscriptionPlan/get-all-with-quantity"
      );
      dispatch({
        type: UserActionTypes.GET_SUBSCRIPTION_PLANS,
        payload: await response.data,
      });
      return Promise.resolve();
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<any>;
        if (serverError && serverError.response) {
          const { errors } = serverError.response.data;
          return Promise.reject(errors);
        }
      }
      return Promise.reject();
    }
  };