export interface ISubscription{
    id: string;
    name: string;
    displayName: string;
    notes?: string;
    tariff: number;
    imageName?: string;
    website?: string;
    date: Date;
    nextPaymentDate: Date;
    createdDate: Date;
    isCustom: boolean;
    billingCycle: IBillingCycle;
    service?: IService;
    customService?: IService;
    transactions: IShortTransaction[];
    bank?: IBank;
    card?: ICard;
    userCustomPaymentMethod: IUserCustomPaymentMethod;
    billingCycleGenerationTransactionsIds? : string[];
}
export interface IGetSubscriptionsResponse{
    subscriptions: ISubscription[];
}
export interface IBillingCycle{
    name: string;
}
export interface IService{
    name: string;
    logo: string;
}
export interface IShortTransaction{
    id:string,
    createdDate: Date;
    sum: number;
    currency: ICurrency;
    // createdInDatabaseDate
}
export interface ICurrency{
    lettersSign?: string;
}
export interface IBank{
    name: string;
    logo: string;
}
export interface IUserCustomPaymentMethod{
    name: string;
    card?: string;
}
export interface ICard{
    cardNumber: string;
}
// export interface ITransaction

export interface IGetSubscriptionsRequest{
    userId: string;
}

//REDUX

export interface IAdminSubscriptionState{
    isLoading: boolean;
    subscriptions: ISubscription[];
}
export enum SubscriptionActionTypes{
    GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS',
    GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS',
    GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR',
}

export interface GetSubscriptionsAction{
    type: SubscriptionActionTypes.GET_SUBSCRIPTIONS;
}
export interface GetSubscriptionsSuccessAction{
    type: SubscriptionActionTypes.GET_SUBSCRIPTIONS_SUCCESS;
    payload: ISubscription[];
}
export interface GetSubscriptionsErrorAction{
    type: SubscriptionActionTypes.GET_SUBSCRIPTIONS_ERROR;
    payload: any;
}

export type SubscriptionAction =
| GetSubscriptionsAction 
| GetSubscriptionsSuccessAction
| GetSubscriptionsErrorAction;
