import Swal from "sweetalert2";
import { PremiumFeatureAction, PremiumFeatureActionTypes, PremiumFeatureState } from "./types";

const initialState: PremiumFeatureState = {
    premiumFeatures: [],
    isLoading: false
};

export const premiumFeatureReducer = (state = initialState, action: PremiumFeatureAction) : PremiumFeatureState => {
    switch(action.type){
        case PremiumFeatureActionTypes.GET_PREMIUM_FEATURES:
            return{
                ...state,
                isLoading: true
            }
        case PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_SUCCESS:
            return{
                ...state,
                isLoading: false,
                premiumFeatures: action.payload
            }
        case PremiumFeatureActionTypes.GET_PREMIUM_FEATURES_ERROR:
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! ' + action.payload,
                confirmButtonText: 'OK',
              });
            return{
                ...state,
                isLoading: false
            }
        case PremiumFeatureActionTypes.EDIT_PREMIUM_FEATURE:
            Swal.fire({
                icon: 'success',
                title: 'Nice!',
                text: 'You have successfully edited the premium feature!',
                confirmButtonText: 'OK',
              });
              return state;
            //   {
            //     ...state
            //   }
        default:
            return state;
    }
}