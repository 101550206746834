import {ProfileActions, ProfileActionTypes} from './Profile/types';
import { ProfileState } from './types';

const intialState: ProfileState = {
  profile: {
	  id: null,
	  email: '',
	  name:'',
	  phoneNumber: '',
    langId: null
  },
  loading: false,
  error: "",
};

export const profileReducer = (state = intialState, action: ProfileActions) => {
	switch (action.type) {
    case ProfileActionTypes.PROFILE:
      return {
        ...state,
        loading: true,
      };

    case ProfileActionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: { ...action.payload },
      };

    case ProfileActionTypes.PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}