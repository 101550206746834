import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector';
import '././Navbar.css';
import { faGear, faUser, faRightFromBracket, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActions } from '../../hooks/useActions';
const Navbar = () => {
  const { isAuth, user } = useTypedSelector((store) => store.auth);
  const { LogoutUser } = useActions();
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            Key subscriptions
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
            </ul>
            {isAuth ? (
              <ul className="navbar-nav d-flex align-items-center">
                <li className="nav-item ">
                  {/* <span className="navbar-toggler-icon authorized-content"></span> */}
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="custom-dropdown-toggle">
                      {user.email}
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                      <Dropdown.Item as={Link} to="profile">
                        My Profile
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mx-2"
                        ></FontAwesomeIcon>
                      </Dropdown.Item>
                      {user?.roles?.includes("Administrator") && (
                        <Dropdown.Item as={Link} to="adminPanel/users">
                          Admin Panel
                          <FontAwesomeIcon
                            icon={faScrewdriverWrench}
                            className="mx-2"
                          ></FontAwesomeIcon>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item href="#/action-2">
                        Settings
                        <FontAwesomeIcon
                          icon={faGear}
                          className="mx-2"
                        ></FontAwesomeIcon>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="recover-password">
                        Recover Password
                        <FontAwesomeIcon
                          icon={faGear}
                          className="mx-2"
                        ></FontAwesomeIcon>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => LogoutUser()}>
                        Logout
                        <FontAwesomeIcon
                          icon={faRightFromBracket}
                          className="mx-2"
                        ></FontAwesomeIcon>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <button
                      className="btn btn-secondary"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >
                      
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div> */}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    id="navbarDropdown"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></Link>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    Register
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Navbar;