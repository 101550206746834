export enum ProfileActionTypes {
    PROFILE = "PROFILE",
    PROFILE_SUCCESS = "PROFILE_SUCCESS",
    PROFILE_ERROR = "PROFILE_ERROR",
  }
  
  export interface IProfile {
    id: string | null;
    email: string;
    name: string;
    phoneNumber: string;
    langId: string | null;
  }
  
  export interface ProfileAction {
    type: ProfileActionTypes.PROFILE;
  }
  
  export interface LoginAuthSuccessAction {
    type: ProfileActionTypes.PROFILE_SUCCESS;
    payload: IProfile;
  }
  
  export interface LoginAuthErrorAction {
    type: ProfileActionTypes.PROFILE_ERROR;
    payload: string;
  }
  
  export type ProfileActions =
    | ProfileAction
    | LoginAuthSuccessAction
    | LoginAuthErrorAction;