import React from 'react';
import { Bar } from 'react-chartjs-2';
import { VisitDataItem } from './types';
import { Chart, registerables} from 'chart.js';

//import 'chart.js';



interface ChartProps {
  data: VisitDataItem[];
}

const UserVisitBarChart: React.FC<ChartProps> = ({ data }) => {
  const labels = data.map(item => `${item.monthName} ${item.year}`);
  const values = data.map(item => item.counter);
  Chart.register(...registerables);
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Counter',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: values,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        // type: 'linear', // or 'category', 'logarithmic', etc. as appropriate
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default UserVisitBarChart;